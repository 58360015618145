/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { Component, useContext } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { AppContext } from "~context/AppContext";
import { AWSContext } from "~context/AWSContext";
import { DocumentContext } from "~context/DocumentContext";
import Layout from "~components/Layout";
import PersonaHoverGrid from "~components/PersonaHoverGrid";
import RotatingButtonLink from "~components/RotatingButtonLink";
import SEO from "~components/SEO";
import { fancyWarning } from "~utils/helpers";

import SVGNoIcon from "~components/svg/SVGNoIcon";
import SVGSometimesIcon from "~components/svg/SVGSometimesIcon";
import SVGYesIcon from "~components/svg/SVGYesIcon";

import cheeseburger from "~assets/images/about/cheeseburger.png";
import popcorn from "~assets/images/about/popcorn.png";
import teeth from "~assets/images/about/teeth.png";
import toast from "~assets/images/about/toast.png";
import tomato from "~assets/images/about/tomato.png";
import turkey from "~assets/images/about/turkey.png";

class AboutPageComponent extends Component {
  state = {
    easePercent: 0,
    healthPercent: 0,
    comfortPercent: 0,
    funPercent: 0,
    scrolled: false,
    totalCount: 0
  };

  mounted = false;

  resultFetchCount = 0;

  scrollRef = React.createRef();

  timeouts = [];

  componentDidMount() {
    const { appContext } = this.props;

    if (document) {
      document.addEventListener(`scroll`, this.handleScroll, false);
    }

    appContext.setActivePath(`/about`);
    appContext.setShareUrl(`https://www.myfoodie.id/about`);

    this.mounted = true;

    setTimeout(() => {
      this.getResults();
    });
  }

  componentWillUnmount() {
    this.mounted = false;

    this.clearTimeouts();

    if (document) {
      document.removeEventListener(`scroll`, this.handleScroll, false);
    }
  }

  //

  clearTimeouts = () => {
    if (this.timeouts.length > 0) {
      this.timeouts.forEach(timeout => {
        clearTimeout(timeout);
      });
    }
  };

  handleScroll = () => {
    this.updatePersonaScrollOpacity();
  };

  //

  getResults = () => {
    if (!this.mounted) {
      return;
    }

    const { awsContext } = this.props;
    const { data } = awsContext;

    if (data === null) {
      if (this.resultFetchCount > 10) {
        fancyWarning(`Failed to fetch AWS data`);

        return;
      }

      this.timeouts.push(
        setTimeout(() => {
          this.resultFetchCount += 1;
          this.getResults();
        }, 500)
      );

      return;
    }

    let easeCount = 0;
    let healthCount = 0;
    let comfortCount = 0;
    let funCount = 0;
    let totalCount = 0;

    data.forEach(result => {
      if (typeof result.key !== `string`) {
        return;
      }

      const personaName = result.key.toLowerCase();

      if (
        personaName === `unfoodie` ||
        personaName === `refueller` ||
        personaName === `flavor townie` ||
        personaName === `big tipper` ||
        personaName === `moody foodie` ||
        personaName === `moodie foodie` ||
        personaName === `everyday epicurean`
      ) {
        easeCount += result.count;
      }

      if (
        personaName === `refueller` ||
        personaName === `foodfluencer` ||
        personaName === `moody foodie` ||
        personaName === `moodie foodie` ||
        personaName === `everyday epicurean`
      ) {
        healthCount += result.count;
      }

      if (
        personaName === `gastronaut` ||
        personaName === `big tipper` ||
        personaName === `foodfluencer` ||
        personaName === `everyday epicurean`
      ) {
        funCount += result.count;
      }

      if (
        personaName === `flavor townie` ||
        personaName === `gastronaut` ||
        personaName === `big tipper` ||
        personaName === `foodfluencer` ||
        personaName === `moody foodie` ||
        personaName === `moodie foodie` ||
        personaName === `everyday epicurean`
      ) {
        comfortCount += result.count;
      }

      totalCount += result.count;
    });

    this.setState({
      easePercent: parseInt((easeCount / totalCount) * 100),
      healthPercent: parseInt((healthCount / totalCount) * 100),
      funPercent: parseInt((funCount / totalCount) * 100),
      comfortPercent: parseInt((comfortCount / totalCount) * 100),
      totalCount
    });
  };

  updatePersonaScrollOpacity = () => {
    if (!this.scrollRef || !this.scrollRef.current) {
      return;
    }

    const { documentContext } = this.props;
    const { scrollTop, windowHeight } = documentContext;
    const boundingRect = this.scrollRef.current.getBoundingClientRect();
    const { top } = boundingRect;
    const startThresholdInteractable = top + windowHeight * 0.6667;

    if (scrollTop > startThresholdInteractable) {
      if (!this.state.scrolled) {
        this.setState({
          scrolled: true
        });
      }
    } else if (this.state.scrolled) {
      this.setState({
        scrolled: false
      });
    }
  };

  //

  render() {
    const { documentContext, frontmatter, personas } = this.props;
    const { device } = documentContext;

    const {
      easePercent,
      healthPercent,
      comfortPercent,
      funPercent,
      totalCount
    } = this.state;

    const events = [
      {
        content: (
          <p
            className="b2"
            dangerouslySetInnerHTML={{
              __html: frontmatter.movitationsEaseContent
            }}
          ></p>
        ),
        motivation: `1. EASE`
      },
      {
        content: (
          <p
            className="b2"
            dangerouslySetInnerHTML={{
              __html: frontmatter.movitationsHealthContent
            }}
          ></p>
        ),
        motivation: `2. HEALTH`
      },
      {
        content: (
          <p
            className="b2"
            dangerouslySetInnerHTML={{
              __html: frontmatter.movitationsFunContent
            }}
          ></p>
        ),
        motivation: `3. FUN`
      },
      {
        content: (
          <p
            className="b2"
            dangerouslySetInnerHTML={{
              __html: frontmatter.movitationsComfortContent
            }}
          ></p>
        ),
        motivation: `4. COMFORT`
      }
    ];

    return (
      <>
        <SEO
          frontmatterTitle={frontmatter.title}
          frontmatterDescription={frontmatter.seoDescription}
          frontmatterKeywords={frontmatter.seoKeywords}
        />

        <Layout
          className="about-page relative"
          footerClass="black"
          headerClass="white"
        >
          <section className="w-full relative bg-purple text-white">
            <div className="grid">
              <article
                className={`about-page__intro grid-end-10 grid-start-2 xs:grid-end-22 xs:grid-start-2 ${
                  device === `desktop`
                    ? `h-screen sticky top-0 left-0`
                    : `relative pt-24`
                } flex flex-col justify-center z-10`}
              >
                <h1
                  className={`animation-appear f3 uppercase ${
                    device === `desktop` ? `` : `text-center`
                  }`}
                  style={{ animationDelay: `100ms` }}
                >
                  {frontmatter.heading}
                </h1>

                <p
                  className={`about-page__intro__content animation-appear ${
                    device === `desktop` ? `mt-12` : `mt-10 text-center`
                  } b1`}
                  style={{ animationDelay: `150ms` }}
                >
                  {frontmatter.intro}
                </p>
              </article>

              <article
                className="about-page__big-text__container animation-appear grid-end-10 xs:grid-end-22 grid-start-13 xs:grid-start-2 relative flex flex-col items-center justify-center z-10"
                style={{ animationDelay: `250ms` }}
              >
                {(totalCount === 0 && (
                  <h2 className="about-page__big-text g1 text-center opacity-0">
                    ?
                  </h2>
                )) || (
                  <h2 className="about-page__big-text animation-appear g1 text-center">
                    {totalCount}
                  </h2>
                )}

                <p
                  className={`${
                    device === `desktop` ? `mt-16` : `mt-8`
                  } b2 text-center`}
                >
                  {frontmatter.subheading}
                </p>

                <img
                  className={`${
                    device === `desktop`
                      ? `about-page__intro__image w-full absolute`
                      : `w-3/4 relative mx-auto mb-16`
                  } block`}
                  src={teeth}
                  alt="Teeth"
                />
              </article>
            </div>
          </section>

          <section className="about-page__motivations w-full relative bg-black text-white">
            <div className="grid relative items-start">
              <article
                className={`grid-end-10 xs:grid-end-20 grid-start-2 xs:grid-start-3 ${
                  device === `desktop`
                    ? `sticky top-0 left-0 pt-32 pb-64`
                    : `relative py-16`
                } flex flex-col justify-center`}
              >
                <h2
                  className={`${
                    device === `desktop` ? `` : `mt-4 text-center`
                  } f3 whitespace-pre-wrap`}
                  dangerouslySetInnerHTML={{
                    __html: frontmatter.motivationsHeading
                  }}
                ></h2>

                <p
                  className={`${
                    device === `desktop` ? `mt-16` : `mt-8 text-center`
                  } b1 whitespace-pre-wrap`}
                  dangerouslySetInnerHTML={{
                    __html: frontmatter.motivationsIntro
                  }}
                ></p>
              </article>

              <div
                className={`grid-end-12 xs:grid-end-24 grid-start-13 xs:grid-start-1 relative ${
                  device === `desktop` ? `pt-32 pb-64` : `pb-24`
                } z-10`}
              >
                {events.map((event, index) => {
                  const eventIndex = index;

                  let imageSrc = toast;
                  let marginClass = `mb-12`;

                  switch (eventIndex) {
                    case 1:
                      imageSrc = tomato;
                      break;

                    case 2:
                      imageSrc = popcorn;
                      break;

                    case 3:
                      imageSrc = cheeseburger;
                      marginClass = ``;
                      break;

                    default:
                      break;
                  }

                  return (
                    <article
                      key={`event-${eventIndex}`}
                      className={`relative ${
                        device === `desktop`
                          ? `flex items-center justify-start`
                          : `pb-6`
                      } ${marginClass} px-4`}
                    >
                      {device !== `desktop` && (
                        <img
                          className="w-full relative block"
                          src={imageSrc}
                          alt="Event Food"
                        />
                      )}

                      <div
                        className={`${
                          device === `desktop` ? `w-2/3` : `w-full text-center`
                        } relative block z-10`}
                      >
                        <h3 className="mt-4 f2">{event.motivation}</h3>
                        <div className="mt-12">{event.content}</div>
                      </div>

                      {device === `desktop` && (
                        <div className="about-page__motivations__image w-1/2 absolute">
                          <img
                            className="w-full relative block"
                            src={imageSrc}
                            alt="Event Food"
                          />
                        </div>
                      )}
                    </article>
                  );
                })}
              </div>
            </div>
          </section>

          <section
            ref={this.scrollRef}
            className={`about-page__persona-scroll ${
              this.state.scrolled ? `scrolled` : `pointer-events-none`
            } w-full relative text-white`}
          >
            {device !== `desktop` && (
              <div className="about-page__persona-scroll__banner w-full relative z-20 py-16">
                <div className="relative grid">
                  <article className="grid-end-20 grid-start-3 text-center">
                    <h2
                      className="f3 text-black"
                      dangerouslySetInnerHTML={{
                        __html: frontmatter.personasHeading
                      }}
                    ></h2>

                    <p
                      className="mt-8 b1 text-black"
                      dangerouslySetInnerHTML={{
                        __html: frontmatter.personasContent
                      }}
                    ></p>
                  </article>

                  <div className="about-page__persona-scroll__key grid-end-22 grid-start-2 relative mt-24 mb-8 pt-2 pb-6 text-black">
                    <p className="f3 text-center">Key</p>

                    <div className="w-full relative flex items-center justify-between px-8">
                      <div className="w-1/4 relative flex flex-col items-center justify-center text-center">
                        <SVGYesIcon className="mt-6" color="black" />

                        <p className="mt-2 f4 whitespace-no-wrap">Yes</p>
                      </div>

                      <div className="w-1/4 relative flex flex-col items-center justify-center text-center">
                        <SVGNoIcon className="mt-6" color="black" />

                        <p className="mt-2 f4 whitespace-no-wrap">No</p>
                      </div>

                      <div className="w-1/4 relative flex flex-col items-center justify-center text-center">
                        <SVGSometimesIcon className="mt-6" color="black" />

                        <p className="mt-2 f4 whitespace-no-wrap">Sometimes</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <PersonaHoverGrid
              className="w-screen h-screen sticky top-0 right-0 left-0"
              personas={personas}
              stats
            />

            {device !== `mobile` && (
              <div className="about-page__persona-scroll__background w-full h-full absolute z-10 top-0 right-0 left-0 bg-black pointer-events-none"></div>
            )}

            {device !== `mobile` && (
              <div className="about-page__persona-scroll__banner w-screen h-screen absolute z-20 top-0 right-0 left-0 flex items-center justify-center">
                <div className="relative grid items-center">
                  <article className="grid-end-8 grid-start-2">
                    <h2
                      className="f3"
                      dangerouslySetInnerHTML={{
                        __html: frontmatter.personasHeading
                      }}
                    ></h2>

                    <p
                      className="mt-12 b2"
                      dangerouslySetInnerHTML={{
                        __html: frontmatter.personasContent
                      }}
                    ></p>
                  </article>

                  <div className="about-page__persona-scroll__key grid-end-8 grid-start-14 relative my-8 pt-2 pb-6 text-white test">
                    <p className="my-8 f3 text-center">Key</p>

                    <div className="w-full relative flex items-center justify-center">
                      <div className="w-1/4 relative flex flex-col items-center justify-center text-center">
                        <SVGYesIcon className="mt-6 mx-4" color="white" />

                        <p className="mt-2 f4 whitespace-no-wrap">Yes</p>
                      </div>

                      <div className="w-1/4 relative flex flex-col items-center justify-center text-center">
                        <SVGNoIcon className="mt-6 mx-4" color="white" />

                        <p className="mt-2 f4 whitespace-no-wrap">No</p>
                      </div>

                      <div className="w-1/4 relative flex flex-col items-center justify-center text-center">
                        <SVGSometimesIcon className="mt-6 mx-4" color="white" />

                        <p className="mt-2 f4 whitespace-no-wrap">Sometimes</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </section>

          <section className="about-page__takeaways w-full relative bg-yellow text-black">
            <div className="relative grid items-start">
              <article
                className={`grid-end-8 xs:grid-end-24 grid-start-2 xs:grid-start-1 ${
                  device === `desktop`
                    ? `sticky top-0 left-0 pt-32 mb-64`
                    : `pt-24`
                } z-10`}
              >
                <h2
                  className={`${device === `desktop` ? `` : `text-center`} f3`}
                >
                  {frontmatter.takeawayHeading}
                </h2>

                <p
                  className={`${
                    device === `desktop`
                      ? `mt-12 b2`
                      : `mt-8 px-8 b1 text-center`
                  }`}
                >
                  {frontmatter.takeawayContent}
                </p>
              </article>

              <ul
                className={`grid-end-12 sm:grid-end-22 grid-start-12 sm:grid-start-2 relative flex flex-wrap z-10 ${
                  device === `desktop` ? `pt-32 pb-48` : `pb-24`
                }`}
              >
                <li
                  className={`about-page__takeaway ${
                    device === `desktop`
                      ? `w-1/2 mb-24`
                      : `w-full pt-16 text-center`
                  } relative block`}
                >
                  <h4 className="b3">We found:</h4>

                  <h3
                    className={`mt-2 ${
                      device === `desktop` ? `f3` : `f2`
                    } uppercase`}
                    dangerouslySetInnerHTML={{
                      __html: `${easePercent}% ${frontmatter.takeawayEaseFoundHeading}`
                    }}
                  ></h3>

                  <p
                    className={`${device === `desktop` ? `mt-4` : `mt-6`} b3`}
                    dangerouslySetInnerHTML={{
                      __html: frontmatter.takeawayEaseFoundContent
                    }}
                  ></p>
                </li>

                <li
                  className={`about-page__takeaway ${
                    device === `desktop`
                      ? `w-1/2 mb-24`
                      : `w-full pt-16 text-center`
                  } relative block`}
                >
                  <h4 className="b3">We suggest:</h4>

                  <h3
                    className={`mt-2 ${
                      device === `desktop` ? `f3` : `f2`
                    } uppercase`}
                    dangerouslySetInnerHTML={{
                      __html: frontmatter.takeawayEaseSuggestHeading
                    }}
                  ></h3>

                  <p
                    className={`${device === `desktop` ? `mt-4` : `mt-6`} b3`}
                    dangerouslySetInnerHTML={{
                      __html: frontmatter.takeawayEaseSuggestContent
                    }}
                  ></p>
                </li>

                {/* // */}

                <li
                  className={`about-page__takeaway ${
                    device === `desktop`
                      ? `w-1/2 mb-24`
                      : `w-full pt-16 text-center`
                  } relative block`}
                >
                  <h3
                    className={`mt-2 ${
                      device === `desktop` ? `f3` : `f2`
                    } uppercase`}
                    dangerouslySetInnerHTML={{
                      __html: `${healthPercent}% ${frontmatter.takeawayHealthFoundHeading}`
                    }}
                  ></h3>

                  <p
                    className={`${device === `desktop` ? `mt-4` : `mt-6`} b3`}
                    dangerouslySetInnerHTML={{
                      __html: frontmatter.takeawayHealthFoundContent
                    }}
                  ></p>
                </li>

                <li
                  className={`about-page__takeaway ${
                    device === `desktop`
                      ? `w-1/2 mb-24`
                      : `w-full pt-16 text-center`
                  } relative block`}
                >
                  <h3
                    className={`mt-2 ${
                      device === `desktop` ? `f3` : `f2`
                    } uppercase`}
                    dangerouslySetInnerHTML={{
                      __html: frontmatter.takeawayHealthSuggestHeading
                    }}
                  ></h3>

                  <p
                    className={`${device === `desktop` ? `mt-4` : `mt-6`} b3`}
                    dangerouslySetInnerHTML={{
                      __html: frontmatter.takeawayHealthSuggestContent
                    }}
                  ></p>
                </li>

                {/* // */}

                <li
                  className={`about-page__takeaway ${
                    device === `desktop`
                      ? `w-1/2 mb-24`
                      : `w-full pt-16 text-center`
                  } relative block`}
                >
                  <h3
                    className={`mt-2 ${
                      device === `desktop` ? `f3` : `f2`
                    } uppercase`}
                    dangerouslySetInnerHTML={{
                      __html: `${comfortPercent}% ${frontmatter.takeawayComfortFoundHeading}`
                    }}
                  ></h3>

                  <p
                    className={`${device === `desktop` ? `mt-4` : `mt-6`} b3`}
                    dangerouslySetInnerHTML={{
                      __html: frontmatter.takeawayComfortFoundContent
                    }}
                  ></p>
                </li>

                <li
                  className={`about-page__takeaway ${
                    device === `desktop`
                      ? `w-1/2 mb-24`
                      : `w-full pt-16 text-center`
                  } relative block`}
                >
                  <h3
                    className={`mt-2 ${
                      device === `desktop` ? `f3` : `f2`
                    } uppercase`}
                    dangerouslySetInnerHTML={{
                      __html: frontmatter.takeawayComfortSuggestHeading
                    }}
                  ></h3>

                  <p
                    className={`${device === `desktop` ? `mt-4` : `mt-6`} b3`}
                    dangerouslySetInnerHTML={{
                      __html: frontmatter.takeawayComfortSuggestContent
                    }}
                  ></p>
                </li>

                {/* // */}

                <li
                  className={`about-page__takeaway ${
                    device === `desktop`
                      ? `w-1/2 mb-24`
                      : `w-full pt-16 text-center`
                  } relative block`}
                >
                  <h3
                    className={`mt-2 ${
                      device === `desktop` ? `f3` : `f2`
                    } uppercase`}
                    dangerouslySetInnerHTML={{
                      __html: `${funPercent}% ${frontmatter.takeawayFunFoundHeading}`
                    }}
                  ></h3>

                  <p
                    className={`${device === `desktop` ? `mt-4` : `mt-6`} b3`}
                    dangerouslySetInnerHTML={{
                      __html: frontmatter.takeawayFunFoundContent
                    }}
                  ></p>
                </li>

                <li
                  className={`about-page__takeaway ${
                    device === `desktop`
                      ? `w-1/2 mb-24`
                      : `w-full pt-16 text-center`
                  } relative block`}
                >
                  <h3
                    className={`mt-2 ${
                      device === `desktop` ? `f3` : `f2`
                    } uppercase`}
                    dangerouslySetInnerHTML={{
                      __html: frontmatter.takeawayFunSuggestHeading
                    }}
                  ></h3>

                  <p
                    className={`${device === `desktop` ? `mt-4` : `mt-6`} b3`}
                    dangerouslySetInnerHTML={{
                      __html: frontmatter.takeawayFunSuggestContent
                    }}
                  ></p>
                </li>
              </ul>

              {device !== `mobile` && (
                <img
                  className="about-page__takeaways__image w-2/3 absolute right-0 bottom-0"
                  src={turkey}
                  alt="Turkey"
                />
              )}
            </div>
          </section>

          <section className="about-page__contact w-full relative overflow-hidden bg-ultramarine text-peach">
            <div className="grid">
              <article
                className={`about-page__form-container grid-end-10 grid-start-2 sm:grid-end-22 sm:grid-start-2 relative ${
                  device === `desktop` ? `pt-32 pb-48` : `pt-24 pb-16`
                } z-10`}
              >
                <h2
                  className={`${
                    device === `desktop` ? `` : `px-4 text-center`
                  } f3`}
                  dangerouslySetInnerHTML={{
                    __html: frontmatter.contactHeading
                  }}
                ></h2>

                <p
                  className={`${
                    device === `desktop`
                      ? `mt-12 b2`
                      : `mt-8 px-4 b1 text-center`
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: frontmatter.contactContent
                  }}
                ></p>
              </article>

              <form
                className={`about-page__form grid-end-10 xs:grid-end-22 grid-start-13 xs:grid-start-2 relative z-10 ${
                  device === `desktop` ? `pt-32 pb-48` : ``
                }`}
              >
                <input
                  name="name"
                  className={`w-full relative block mb-3 px-4 ${
                    device === `desktop` ? `b2` : `b1`
                  } text-black`}
                  type="text"
                  placeholder="Enter your name"
                />

                <input
                  name="email"
                  className={`w-full relative block mb-3 px-4 ${
                    device === `desktop` ? `b2` : `b1`
                  } text-black`}
                  type="email"
                  placeholder="Email address"
                />

                <select
                  className={`w-full relative mb-3 px-4 block ${
                    device === `desktop` ? `b2` : `b1`
                  } text-black cursor-pointer`}
                  name="location"
                  defaultValue="Location"
                >
                  <option>America</option>
                  <option>Japan</option>
                  <option>Australia</option>
                </select>

                <select
                  className={`w-full relative px-4 block text-black cursor-pointer ${
                    device === `desktop` ? `b2` : `b1`
                  }`}
                  name="type"
                  defaultValue="Food Type"
                >
                  <option>Pescetarian</option>
                  <option>Vegetarian</option>
                  <option>Vegan</option>
                  <option>Gluten-Free</option>
                  <option>Other</option>
                </select>

                <label
                  htmlFor="newsletter"
                  className={`w-full relative flex items-center justify-start text-white ${
                    device === `desktop` ? `caption` : `my-6 b2`
                  }`}
                >
                  <input
                    name="newsletter"
                    className="relative block mr-3 cursor-pointer"
                    type="checkbox"
                  />

                  <span>
                    Sign up for exclusive offers and info on upcoming events
                  </span>
                </label>

                <div
                  className={`w-full relative flex ${
                    device === `desktop`
                      ? `justify-end pr-24`
                      : `justify-center mt-6`
                  }`}
                >
                  <RotatingButtonLink
                    className={`infinispin relative ${
                      device === `desktop` ? `` : `mx-auto`
                    } b1`}
                    filled
                    onClick={this.formSubmit}
                    text="Submit"
                  />

                  {/* <input
                    className="button button--white button--no-border px-8 pb-2 relative block mt-3 cursor-pointer f4 bg-black text-white"
                    type="submit"
                    value="Submit"
                  /> */}
                </div>
              </form>
            </div>

            <img
              className={`about-page__contact__image ${
                device === `desktop` ? `absolute` : `relative`
              }`}
              src={cheeseburger}
              alt="Cheeseburger"
            />
          </section>
        </Layout>
      </>
    );
  }
}

AboutPageComponent.defaultProps = {
  appContext: {
    // setActivePath: () => {},
    // setIconColor: () => {},
    // setShareUrl: () => {}
  },
  frontmatter: {
    title: ``,
    seoDescription: ``,
    seoKeywords: ``
  }
};

AboutPageComponent.propTypes = {
  appContext: PropTypes.shape({
    // setActivePath: PropTypes.func,
    // setIconColor: PropTypes.func
  }),
  frontmatter: PropTypes.shape({
    title: PropTypes.string,
    seoDescription: PropTypes.string,
    seoKeywords: PropTypes.string
  })
};

//

const AboutPage = ({ data }) => {
  const appContext = useContext(AppContext);
  const awsContext = useContext(AWSContext);
  const documentContext = useContext(DocumentContext);
  const { frontmatter } = data.markdownRemark;
  const personas = [];

  data.allMarkdownRemark.edges.forEach(edge => {
    const persona = edge.node.frontmatter;

    persona.url = edge.node.fields.slug;

    personas.push(persona);
  });

  return (
    <AboutPageComponent
      appContext={appContext}
      awsContext={awsContext}
      documentContext={documentContext}
      frontmatter={frontmatter}
      personas={personas}
    />
  );
};

export default AboutPage;

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({}).isRequired
    })
  }).isRequired
};

export const query = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        intro
        subheading
        motivationsHeading
        motivationsIntro
        movitationsEaseContent
        movitationsHealthContent
        movitationsFunContent
        movitationsComfortContent
        personasHeading
        personasContent
        takeawayHeading
        takeawayContent
        takeawayEaseFoundHeading
        takeawayEaseFoundContent
        takeawayEaseSuggestHeading
        takeawayEaseSuggestContent
        takeawayHealthFoundHeading
        takeawayHealthFoundContent
        takeawayHealthSuggestHeading
        takeawayHealthSuggestContent
        takeawayComfortFoundHeading
        takeawayComfortFoundContent
        takeawayComfortSuggestHeading
        takeawayComfortSuggestContent
        takeawayFunFoundHeading
        takeawayFunFoundContent
        takeawayFunSuggestHeading
        takeawayFunSuggestContent
        contactHeading
        contactContent
        seoDescription
        seoKeywords
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "persona-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tagline
            description
            dinnerDate
            dinnerDateText
            colorClasses
            videoPoster {
              childImageSharp {
                fluid(maxWidth: 1024) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
