import React from "react";
import PropTypes from "prop-types";

const SVGSometimesIcon = ({ className, color }) => (
  <svg
    viewBox="0 0 41 41"
    fill="none"
    className={`persona-icon ${className} relative block`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.896 34.523C42.7065 26.7125 42.7065 14.0492 34.896 6.23872C27.0856 -1.57176 14.4223 -1.57176 6.61177 6.23872C-1.19872 14.0492 -1.19872 26.7125 6.61177 34.523C14.4223 42.3335 27.0856 42.3335 34.896 34.523ZM30.8442 16.8631H10.5989L10.5989 22.7886H30.8442L30.8442 16.8631Z"
      fill={color}
    />
  </svg>
);

SVGSometimesIcon.defaultProps = {
  className: ``,
  color: `white`
};

SVGSometimesIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default SVGSometimesIcon;
