import React from "react";
import PropTypes from "prop-types";

const SVGYesIcon = ({ className, color }) => (
  <svg
    viewBox="0 0 41 41"
    fill="none"
    className={`persona-icon ${className} relative block`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM12.9027 25.6951L12.9062 25.6915L9 21.7853L12.5355 18.2497L16.4418 22.156L27.5978 11L31.1333 14.5355L16.4383 29.2306L12.9027 25.6951Z"
      fill={color}
    />
  </svg>
);

SVGYesIcon.defaultProps = {
  className: ``,
  color: `white`
};

SVGYesIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default SVGYesIcon;
