/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { Component, useContext } from "react";
import Img from "gatsby-image";
import { DocumentContext } from "~context/DocumentContext";
import RotatingButtonLink from "~components/RotatingButtonLink";

import SVGNoIcon from "~components/svg/SVGNoIcon";
import SVGYesIcon from "~components/svg/SVGYesIcon";

class PersonaHoverGridComponent extends Component {
  componentDidMount() {}

  //

  render() {
    const { documentContext, className, personas, stats } = this.props;

    const motivations = {
      "Big Tipper": {
        ease: 1,
        health: 0,
        fun: 1,
        comfort: 2
      },
      "Everyday Epicurean": {
        ease: 1,
        health: 1,
        fun: 1,
        comfort: 2
      },
      Gastronaut: {
        ease: 0,
        health: 0,
        fun: 1,
        comfort: 2
      },
      "Moodie Foodie": {
        ease: 2,
        health: 1,
        fun: 0,
        comfort: 1
      },
      "Flavor Townie": {
        ease: 2,
        health: 0,
        fun: 0,
        comfort: 1
      },
      Foodfluencer: {
        ease: 0,
        health: 1,
        fun: 1,
        comfort: 2
      },
      Refueller: {
        ease: 2,
        health: 0,
        fun: 0,
        comfort: 0
      },
      "Whole Foodie": {
        ease: 2,
        health: 1,
        fun: 0,
        comfort: 0
      }
    };

    return (
      <>
        {personas && personas.length && (
          <ul
            className={`persona-hover-grid ${className ||
              `w-full h-full relative`} flex flex-wrap items-center justify-center gpu`}
          >
            {personas &&
              personas.length &&
              personas.map((persona, index) => {
                const personaIndex = index;
                const motivation = motivations[persona.title];

                return (
                  <li
                    key={`persona-${personaIndex}`}
                    className={`persona-hover-grid__persona animation-appear-from-right w-1/4 relative flex items-center justify-center overflow-hidden ${persona.colorClasses}`}
                    style={{
                      animationDelay: `${personaIndex * 75}ms`
                    }}
                  >
                    <article
                      role="presentation"
                      className={`persona-hover-grid__persona__inner w-full h-full relative z-20 overflow-hidden ${
                        stats ? `pt-16` : ``
                      } pb-8 flex flex-col items-center justify-between`}
                    >
                      {stats && (
                        <div
                          className={`persona-hover-grid__persona__color w-full h-full absolute z-10 top-0 right-0 bottom-0 left-0 ${persona.colorClasses}`}
                        ></div>
                      )}

                      <h4 className="opacity-0 mb-2 b3">Persona name:</h4>

                      {stats && (
                        <div className="relative z-20 text-center">
                          <h2
                            className="persona-hover-grid__persona__title px-4 mb-4 f4 text-white"
                            style={{
                              textShadow: `0px 0px 24px rgba(0, 0, 0, 0.4)`
                            }}
                          >
                            {persona.title}
                          </h2>
                        </div>
                      )}

                      {!stats && (
                        <h2
                          className="persona-hover-grid__persona__title text-center pt-8 px-4 f4 text-white"
                          style={{
                            textShadow: `0px 0px 24px rgba(0, 0, 0, 0.4)`
                          }}
                        >
                          {persona.title}
                        </h2>
                      )}

                      {stats && motivation && (
                        <ul className="persona-hover-grid__persona__stats w-full relative z-20 flex items-center justify-center my-2">
                          <li className="relative mx-2 flex flex-col items-center justify-center text-center">
                            {(motivation.ease && <SVGYesIcon />) || (
                              <SVGNoIcon />
                            )}

                            <h5 className="mt-2 b3 whitespace-no-wrap">Ease</h5>
                          </li>

                          <li className="relative mx-2 flex flex-col items-center justify-center text-center">
                            {(motivation.health && <SVGYesIcon />) || (
                              <SVGNoIcon />
                            )}

                            <h5 className="mt-2 b3 whitespace-no-wrap">
                              Health
                            </h5>
                          </li>

                          <li className="relative mx-2 flex flex-col items-center justify-center text-center">
                            {(motivation.fun && <SVGYesIcon />) || (
                              <SVGNoIcon />
                            )}

                            <h5 className="mt-2 b3 whitespace-no-wrap">Fun</h5>
                          </li>

                          <li className="relative mx-2 flex flex-col items-center justify-center text-center">
                            {(motivation.comfort && <SVGYesIcon />) || (
                              <SVGNoIcon />
                            )}

                            <h5 className="mt-2 b3 whitespace-no-wrap">
                              Comfort
                            </h5>
                          </li>
                        </ul>
                      )}

                      <RotatingButtonLink
                        className="persona-hover-grid__persona__button infinispin relativ z-20 whitespace-no-wrap"
                        filled
                        path={persona.url}
                        text="See more"
                      />
                    </article>

                    <Img
                      className="persona-hover-grid__persona__poster w-full h-full absolute top-0 right-0 bottom-0 left-0"
                      fluid={persona.videoPoster.childImageSharp.fluid}
                      alt={persona.title}
                    />
                  </li>
                );
              })}
          </ul>
        )}
      </>
    );
  }
}

const PersonaHoverGrid = ({ className, personas, stats }) => {
  const documentContext = useContext(DocumentContext);

  return (
    <PersonaHoverGridComponent
      documentContext={documentContext}
      className={className}
      personas={personas}
      stats={stats}
    />
  );
};

export default PersonaHoverGrid;
