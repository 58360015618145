import React from "react";
import PropTypes from "prop-types";

const SVGNoIcon = ({ className, color }) => (
  <svg
    viewBox="0 0 41 41"
    fill="none"
    className={`persona-icon ${className} relative block`}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.5 20C40.5 31.0457 31.5457 40 20.5 40C9.4543 40 0.5 31.0457 0.5 20C0.5 8.9543 9.4543 0 20.5 0C31.5457 0 40.5 8.9543 40.5 20ZM37.3413 19.9992C37.3413 29.3008 29.8008 36.8413 20.4992 36.8413C11.1975 36.8413 3.65705 29.3008 3.65705 19.9992C3.65705 10.6975 11.1975 3.15705 20.4992 3.15705C29.8008 3.15705 37.3413 10.6975 37.3413 19.9992ZM11.0243 25.9991L16.898 20.1254L11.0249 14.2522L14.7465 10.5306L20.6196 16.4037L26.4928 10.5306L30.2144 14.2522L24.3413 20.1254L30.215 25.9991L26.4934 29.7207L20.6196 23.847L14.7459 29.7207L11.0243 25.9991Z"
      fill={color}
    />
  </svg>
);

SVGNoIcon.defaultProps = {
  className: ``,
  color: `white`
};

SVGNoIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default SVGNoIcon;
